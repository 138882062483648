.sessionList {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.sessionListItem {
  border: 1px solid var(--color-border-light);
  border-radius: 6px;
  min-height: 56px;
}

.sessionListAvatar {
  display: flex;
  min-width: unset;
  padding-right: var(--space-1);
}

.sessionListSecondaryAction {
  /* InputAdornment */
  right: 14px;
}

.button {
  padding: var(--space-1) var(--space-2);
}
