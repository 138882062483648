.container :global .MuiGrid-item {
  padding: var(--space-3) 0;
}

.container :global .MuiGrid-item:first-of-type {
  padding: 0 0 var(--space-3) 0;
}

.container :global .MuiGrid-item:last-of-type {
  padding: var(--space-3) 0 0 0;
}

.infoIcon {
  position: absolute;
  top: var(--space-3);
  right: var(--space-3);
}
