.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.button {
  padding: var(--space-1) var(--space-4);
  margin-top: var(--space-3);
}

.details {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
}
