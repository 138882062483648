.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.icon {
  padding: var(--space-2);
}

.chain {
  margin: 2px;
}

.origin {
  padding: var(--space-1) var(--space-2);
  background: var(--color-border-background);
  border-radius: 6px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.alert {
  width: 100%;
  text-align: left;
}

.checkbox {
  text-align: left;
  margin-top: var(--space-2);
}

.divider {
  margin: var(--space-3) calc(-1 * var(--space-4));
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.button {
  padding: var(--space-1) var(--space-4);
  min-width: 130px;
}
