.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionIcon {
  color: currentColor;
  vertical-align: middle;
  margin-right: var(--space-1);
  font-size: inherit;
}

.list {
  padding: var(--space-2);
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.listItemAvatar {
  min-width: unset;
  margin-right: var(--space-2);
}

.avatar {
  width: 16px;
  height: 16px;
  font-size: 11px;
}
